import React, {
  createContext, useState, ReactElement,
  useEffect,
} from 'react';

import { useParams } from 'react-router-dom';

import { INITIAL_FORM_DATA, OnboardingFormData } from './defaultFormData';
import { OnboardingParams } from './types';

type FlowStep = {
  element: ReactElement,
  breadCrumb?: string,
};

export type EligibilityFormCustomization = {
  showEmployeeId?: boolean,
  showAddressField?: boolean,
  employeeIdFieldRename?: string,
}

export type OnboardingFlowContextValue = {
  currentStepIndex: number,
  currentStep: FlowStep,
  steps: FlowStep[],
  back: () => void,
  next: () => void,
  onboardingFormData: OnboardingFormData,
  eligibilityFormCustomization: EligibilityFormCustomization,
  setEligibilityFormCustomization: (arg0: EligibilityFormCustomization) => void,
  updateFormData: (arg0: Partial<OnboardingFormData>) => void,
  resetFormData: () => void;
  setSteps: (arg0: FlowStep[]) => void,
  uid: string,
  setUid: (uid: string) => void,
}

export const initalOnboardingFlowContext: OnboardingFlowContextValue = {
  currentStepIndex: undefined,
  currentStep: undefined,
  steps: undefined,
  back: undefined,
  next: undefined,
  onboardingFormData: undefined,
  eligibilityFormCustomization: {},
  setEligibilityFormCustomization: undefined,
  updateFormData: undefined,
  resetFormData: undefined,
  setSteps: undefined,
  uid: undefined,
  setUid: undefined,
};

export const OnboardingFlowContext = createContext(initalOnboardingFlowContext);

export const OnboardingFlowProvider = (props: { children: React.ReactElement }) => {
  const { product } = useParams<OnboardingParams>();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingFormData, setOnboardingFormData] = useState(INITIAL_FORM_DATA);
  const [eligibilityFormCustomization, setEligibilityFormCustomization] = useState<EligibilityFormCustomization>({});
  const [steps, setSteps] = useState([]);
  const [uid, setUid] = useState<string>();

  const updateFormData = (newData: Partial<OnboardingFormData>) => {
    setOnboardingFormData((currData) => ({ ...currData, ...newData }));
  };

  useEffect(() => {
    if (product === 'sleepio') {
      updateFormData({ personEnrolling: 'patient' });
    }
  }, [product]);

  const resetFormData = () => {
    setOnboardingFormData({ ...INITIAL_FORM_DATA });
  };

  const next = () => {
    setCurrentStepIndex((i) => (i >= steps.length - 1 ? i : i + 1));
  };

  const back = () => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  };

  return (
    <OnboardingFlowContext.Provider
      value={{
        back,
        next,
        currentStepIndex,
        currentStep: steps[currentStepIndex] || undefined,
        steps,
        onboardingFormData,
        eligibilityFormCustomization,
        setEligibilityFormCustomization,
        updateFormData,
        resetFormData,
        setSteps,
        uid,
        setUid,
      }}
    >
      {props.children}
    </OnboardingFlowContext.Provider>
  );
};
